import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"
import { navigator } from "@hotwired/turbo"
import { areaData } from '../map/jma_area'
import { cityData } from '../map/jma_city'
import Notification from "../utils/notification";
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style, Icon, Text } from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import i18next from "../utils/i18next"
import map from "../utils/map"

export function intensityText(name, type) {
    if (type === "intensity") {
        return `${i18next.t("xml.report.intensity_text")}${i18next.t(`xml.report.intensity.${name}`)}`
    } else {
        return i18next.t(`xml.report.tsunami.${name}`)
    }
}

export default class extends Controller {

    static targets = ["report", "newReport", "listBottom", "list"]
    static values = {
        activeId: String,
        page: { type: Number, default: 1 },
        pages: Number,
        pageName: { type: String, default: "event" }
    }

    initialize() {
    }

    connect() {
        // if (this.hasReportTarget) {
        //     this.reportTarget.click()
        // }
        if (this.activeIdValue) {
            document.getElementById(this.activeIdValue).click()
        } else {
            if (this.hasReportTarget) {
                this.reportTarget.click()
            }
        }
    }

    disconnect() {
    }

    showProgressBar() {
        navigator.delegate.adapter.progressBar.setValue(0)
        navigator.delegate.adapter.progressBar.show()
    }

    hideProgressBar() {
        navigator.delegate.adapter.progressBar.setValue(1)
        navigator.delegate.adapter.progressBar.hide()
    }

    withProgress(request) {
        this.showProgressBar()

        return request.then((response) => {
            this.hideProgressBar()
            return response
        })
    }

    get(url, options) {
        const request = new FetchRequest("get", url, options)
        return this.withProgress(request.perform())
    }

    viewUrl(event) {
        event.preventDefault()
        const url = event.currentTarget.dataset.url
        window.open(url, "_blank").focus()
    }

    newReportTargetConnected(target) {
        const latestSwitch = localStorage.getItem('latestSwitch')
        if (latestSwitch !== 'false') {
            target.click()
        }
    }

    async getLatestInfoFromReportLists(type, currentReportJSON) {
        const currentReportID = currentReportJSON.id
        let currentReportsJSON = {}
        if (this.pageNameValue === "event") {
            const response = await this.get(`/api/v1/events/${this.activeIdValue}/search_intensity`, { query: { name: type, report_id: currentReportID } })
            const body = await response.json
            return body
        } else {
            if (this.hasNewReportTarget) {
                currentReportsJSON = this.newReportTargets.concat(this.reportTargets).map((html) => JSON.parse(html.dataset.json))
            } else {
                currentReportsJSON = this.reportTargets.map((html) => JSON.parse(html.dataset.json))
            }
        }
        let report = null
        currentReportsJSON.forEach((json) => {
            if (json.report.id < currentReportID && json.report.name === type && report === null) {
                report = json
            }
        })
        return report
    }

    async reportInfoWrite(shindo, reportInfo, type = "intensity") {
        let intensityHTML = `<div class="accordion pt-2" id="accordionIntensity">`
        var keys = []
        if (type === "intensity") {
            keys = ["7", "6+", "6-", "5+", "5-", "4", "3", "2", "1"]
        } else {
            keys = ["alert", "warning", "notice"]
        }
        for (const intensity of keys) {
            if (shindo[intensity]) {
                intensityHTML += `
                <div class="accordion-item">
                <h2 class="accordion-header" id="heading${intensity}">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse${intensity}" aria-expanded="true" aria-controls="collapse${intensity}">
                  ${intensityText(intensity, type)}
                  </button>
                </h2>
                <div id="collapse${intensity}" class="accordion-collapse collapse show" aria-labelledby="heading${intensity}" data-bs-parent="#accordion${intensity}">
                  <div class="accordion-body">`
                for (const area of shindo[intensity]) {
                    intensityHTML += `<nobr class="pe-1">${area}</nobr>`
                }
                intensityHTML += `</div>
                </div>
              </div>`
            }
        }
        intensityHTML += `</div>`
        reportInfo.innerHTML += intensityHTML
    }

    async updateMap(event) {
        event.preventDefault()
        if (this.activeIdValue) {
            document.getElementById(this.activeIdValue).classList.remove("active")
        }
        event.currentTarget.classList.add("active")
        const reportId = event.currentTarget.id
        this.activeIdValue = reportId
        const report = JSON.parse(event.currentTarget.dataset.json).report
        if (window.mapLoaded) {
            this.updateShinyMap(report)
        } else {
            map.once("rendercomplete", () => {
                this.updateShinyMap(report)
                window.mapLoaded = true
            });
        }

    }
    async updateShinyMap(report) {
        window.showTitle()
        window.hideFullscreenTip()
        window.hideTsunamiLegend()
        window.hideEEWLegend()
        window.hideInfoLegend()
        window.setInfoLegend("")
        map.removeLayer(window.JapanSimpleMapLayer)
        map.removeLayer(window.shindoLayer);
        map.removeLayer(window.JapanDetailedMapLayer);
        map.removeLayer(window.tsunamiWarningLayer);
        map.removeLayer(window.JapanEEWMapLayer);
        map.removeLayer(window.EEWLayer);
        map.removeLayer(window.JapanPrefMapLayer);
        window.setFooter(`Generated by Shiny-Map / ©2024 Shiny Project / Data Source: 気象庁`)
        const reportInfo = document.getElementById("reportInfo")
        reportInfo.innerHTML = `${report.name}`
        if (report.url) {
            reportInfo.innerHTML += ` <a href="${report.url}" target="_blank">View</a>`
        }
        if (report.head.headline_text) {
            reportInfo.innerHTML += `<br/>${report.head.headline_text}`
        }
        if (report.name == "震度速報") {
            window.setTitle(i18next.t("xml.report.name.intensity_early_report"))

            if (report.head.info_type === "取消") {
                if (report.text?.text) {
                    window.setFullscreenTip(report.text.text)
                    window.showFullscreenTip()
                }
                return;
            }
            const shindo = report.earth_quake.intensity.observation.data.area
            map.addLayer(JapanSimpleMapLayer)
            const shindoGeoJson = {
                type: "FeatureCollection",
                features: [],
            };
            this.reportInfoWrite(shindo, reportInfo)

            // 各地震度
            for (const intensity of ["1", "2", "3", "4", "5-", "5+", "6-", "6+", "7"]) {
                if (shindo[intensity]) {
                    for (const area of shindo[intensity]) {
                        if (areaData[area]) {
                            shindoGeoJson.features.push({
                                type: "Feature",
                                geometry: {
                                    type: "Point",
                                    coordinates: areaData[area].coordinates,
                                },
                                properties: {
                                    intensity: intensity,
                                },
                            });
                        }
                    }
                }
            }
            const shindoStyle = {
                1: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_1.png",
                    }),
                }),
                2: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_2.png",
                    }),
                }),
                3: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_3.png",
                    }),
                }),
                4: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_4.png",
                    }),
                }),
                "5-": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_5-.png",
                    }),
                }),
                "5+": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_5+.png",
                    }),
                }),
                "6-": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_6-.png",
                    }),
                }),
                "6+": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_6+.png",
                    }),
                }),
                7: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_7.png",
                    }),
                }),
            };
            const get_report = await this.getLatestInfoFromReportLists("震源に関する情報", report)
            const epicenter_report = get_report?.report
            if (epicenter_report?.earth_quake?.epicenters) {
                const epicenter = [epicenter_report.earth_quake.epicenters[0].longitude, epicenter_report.earth_quake.epicenters[0].latitude]

                shindoGeoJson.features.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: epicenter,
                    },
                    properties: {
                        intensity: "x",
                    },
                });
                shindoStyle["x"] = new Style({
                    image: new Icon({
                        scale: 0.1,
                        src: "/data/images/epicenter.png",
                    }),
                    text: new TextStyle({
                        font: '13px sans-serif',
                        offsetY: 25,
                        text: epicenter_report.earth_quake.epicenters[0].name,
                        placement: 'line',
                        fill: new Fill({
                            color: '#000'
                        }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2
                        })
                    })
                })
            }
            const styleFunction = (feature) => {
                return shindoStyle[feature.getProperties()["intensity"]];
            };
            const shindoLayer = new VectorLayer({
                source: new VectorSource({
                    features: new GeoJSON().readFeatures(shindoGeoJson, {
                        featureProjection: "EPSG:3857",
                    }),
                }),
                style: styleFunction,
            });
            window.shindoLayer = shindoLayer;
            shindoLayer.set("name", "shindo");
            map.addLayer(shindoLayer);
            map.getView().fit(shindoLayer.getSource().getExtent(), {
                padding: [50, 50, 50, 50],
                maxZoom: 7.9,
            });
        } else if (report.name == "震源に関する情報") {
            window.setTitle(i18next.t("xml.report.name.epicenter_report"))
            if (report.head.info_type === "取消") {
                if (report.text?.text) {
                    window.setFullscreenTip(report.text.text)
                    window.showFullscreenTip()
                }
                return;
            }
            const epicenter = [report.earth_quake.epicenters[0].longitude, report.earth_quake.epicenters[0].latitude]
            const shindoGeoJson = {
                type: "FeatureCollection",
                features: [],
            };
            const intensity_info = await this.getLatestInfoFromReportLists("震度速報", report)
            if (intensity_info) {
                const shindo = intensity_info.report.earth_quake.intensity.observation.data.area
                this.reportInfoWrite(shindo, reportInfo)
                for (const intensity of ["1", "2", "3", "4", "5-", "5+", "6-", "6+", "7"]) {
                    if (shindo[intensity]) {
                        for (const area of shindo[intensity]) {
                            if (areaData[area]) {
                                shindoGeoJson.features.push({
                                    type: "Feature",
                                    geometry: {
                                        type: "Point",
                                        coordinates: areaData[area].coordinates,
                                    },
                                    properties: {
                                        intensity: intensity,
                                    },
                                });
                            }
                        }
                    }
                }
            }

            // 震中

            shindoGeoJson.features.push({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: epicenter,
                },
                properties: {
                    intensity: "x",
                },
            });
            map.addLayer(JapanSimpleMapLayer);
            const shindoStyle = {
                x: new Style({
                    image: new Icon({
                        scale: 0.1,
                        src: "/data/images/epicenter.png",
                    }),
                    text: new TextStyle({
                        font: '13px sans-serif',
                        offsetY: 25,
                        text: report.earth_quake.epicenters[0].name,
                        placement: 'line',
                        fill: new Fill({
                            color: '#000'
                        }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 2
                        })
                    })
                }),
                1: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_1.png",
                    }),
                }),
                2: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_2.png",
                    }),
                }),
                3: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_3.png",
                    }),
                }),
                4: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_4.png",
                    }),
                }),
                "5-": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_5-.png",
                    }),
                }),
                "5+": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_5+.png",
                    }),
                }),
                "6-": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_6-.png",
                    }),
                }),
                "6+": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_6+.png",
                    }),
                }),
                7: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_7.png",
                    }),
                }),
            };
            const styleFunction = (feature) => {
                return shindoStyle[feature.getProperties()["intensity"]];
            };
            const shindoLayer = new VectorLayer({
                source: new VectorSource({
                    features: new GeoJSON().readFeatures(shindoGeoJson, {
                        featureProjection: "EPSG:3857",
                    }),
                }),
                style: styleFunction,
            });
            shindoLayer.set("name", "shindo");
            map.addLayer(shindoLayer);
            window.shindoLayer = shindoLayer;
            map.getView().fit(shindoLayer.getSource().getExtent(), {
                padding: [50, 50, 50, 50],
                maxZoom: 7.9,
            });
        } else if (report.name == "震源・震度に関する情報" || report.name == "顕著な地震の震源要素更新のお知らせ") {
            if (report.head.title == "遠地地震に関する情報") {
                setTitle(i18next.t("xml.report.name.overseas_earthquake"));
            } else if (report.name == "顕著な地震の震源要素更新のお知らせ") {
                setTitle(i18next.t("xml.report.name.earthquake_updated"));
            } else {
                setTitle(i18next.t("xml.report.name.earth_quake_intensity_report"));
            }
            if (report.head.info_type === "取消") {
                if (report.text?.text) {
                    window.setFullscreenTip(report.text.text)
                    window.showFullscreenTip()
                }
                return;
            }
            const epicenter = [report.earth_quake.epicenters[report.earth_quake.epicenters.length - 1].longitude, report.earth_quake.epicenters[report.earth_quake.epicenters.length - 1].latitude]
            const shindoGeoJson = {
                type: "FeatureCollection",
                features: [],
            };
            let intensity_info = report.earth_quake.intensity
            if (report.name == "顕著な地震の震源要素更新のお知らせ") {
                intensity_info = await this.getLatestInfoFromReportLists("震源・震度に関する情報", report)
                intensity_info = intensity_info?.report?.earth_quake?.intensity
            }
            // 震度
            if (intensity_info) {
                const shindo = intensity_info.observation.data.city
                this.reportInfoWrite(shindo, reportInfo)
                for (const intensity of ["1", "2", "3", "4", "5-", "5+", "6-", "6+", "7"]) {
                    if (shindo[intensity]) {
                        for (const area of shindo[intensity]) {
                            if (cityData[area]) {
                                shindoGeoJson.features.push({
                                    type: "Feature",
                                    geometry: {
                                        type: "Point",
                                        coordinates: cityData[area].coordinates,
                                    },
                                    properties: {
                                        intensity: intensity,
                                    },
                                });
                            }
                        }
                    }
                }
            }
            // 震中

            shindoGeoJson.features.push({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: epicenter,
                },
                properties: {
                    intensity: "x",
                },
            });
            map.addLayer(JapanDetailedMapLayer);
            const shindoStyle = {
                x: new Style({
                    image: new Icon({
                        scale: 0.1,
                        src: "/data/images/epicenter.png",
                    }),
                    text: new TextStyle({
                        font: '13px sans-serif',
                        offsetY: 25,
                        text: report.earth_quake.epicenters[0].name,
                        placement: 'line',
                        fill: new Fill({
                            color: '#000'
                        }),
                        stroke: new Stroke({
                            color: '#fff',
                            width: 1
                        })
                    })
                }),
                1: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_1.png",
                    }),
                }),
                2: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_2.png",
                    }),
                }),
                3: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_3.png",
                    }),
                }),
                4: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_4.png",
                    }),
                }),
                "5-": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_5-.png",
                    }),
                }),
                "5+": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_5+.png",
                    }),
                }),
                "6-": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_6-.png",
                    }),
                }),
                "6+": new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_6+.png",
                    }),
                }),
                7: new Style({
                    image: new Icon({
                        scale: 0.05,
                        src: "/data/images/is_7.png",
                    }),
                }),
            };
            const styleFunction = (feature) => {
                return shindoStyle[feature.getProperties()["intensity"]];
            };
            const shindoLayer = new VectorLayer({
                source: new VectorSource({
                    features: new GeoJSON().readFeatures(shindoGeoJson, {
                        featureProjection: "EPSG:3857",
                    }),
                }),
                style: styleFunction,
            });
            shindoLayer.set("name", "shindo");
            map.addLayer(shindoLayer);
            window.shindoLayer = shindoLayer;
            map.getView().fit(shindoLayer.getSource().getExtent(), {
                padding: [50, 50, 50, 50],
                maxZoom: 7.9,
            });
        } else if (report.name == "津波警報・注意報・予報a") {
            setTitle(i18next.t("xml.report.name.tsunami_report"));
            if (report.head.info_type === "取消") {
                if (report.text?.text) {
                    window.setFullscreenTip(report.text.text)
                    window.showFullscreenTip()
                }
                return;
            }
            const type = report.tsunami.tsunami_type
            if (type == "cancel" || type == "forecast") {
                showTsunamiLegend();
                window.setTsunamiLegend(i18next.t("xml.report.tsunami.alert"), i18next.t("xml.report.tsunami.warning"), i18next.t("xml.report.tsunami.notice"))
                setFullscreenTip(i18next.t("map.tips.no_tsunami"));
                showFullscreenTip();
                map.addLayer(JapanPrefMapLayer);
                hideTitle();
                if (type == "forecast") {
                    const tsunamiAreas = report.tsunami.areas
                    if (tsunamiAreas["forecast"]) {
                        reportInfo.innerHTML += `<br/>[若干の海面変動]`
                        for (const area of tsunamiAreas["forecast"]) {
                            if (area) {
                                reportInfo.innerHTML += ` ${area}`
                            }
                        }
                    }
                }
            } else {
                (async () => {
                    const jmaTsunamiArea = JSON.stringify(await import("../map/jma_tsunami_area.json"))
                    const tsunamiAreaData = JSON.parse(jmaTsunamiArea)
                    const tsunamiAreas = report.tsunami.areas
                    const areaLevelMap = {};
                    for (const key of ["alert", "warning", "notice"]) {
                        if (tsunamiAreas[key] && tsunamiAreas[key].length > 0) {
                            for (const name of tsunamiAreas[key]) {
                                areaLevelMap[name] = key;
                            }
                        }
                    }
                    this.reportInfoWrite(tsunamiAreas, reportInfo, "tsunami")
                    for (const key of ["notice", "warning", "alert"]) {
                        if (tsunamiAreas[key] && tsunamiAreas[key].length > 0) {
                            for (const name of tsunamiAreas[key]) {
                                areaLevelMap[name] = key;
                            }
                        }
                    }
                    const areaNames = Object.keys(areaLevelMap);
                    tsunamiAreaData.features = tsunamiAreaData.features.filter((f) => {
                        return areaNames.includes(f.properties.name);
                    });
                    showTsunamiLegend();
                    map.addLayer(JapanPrefMapLayer);
                    const coastlineStyles = {
                        notice: new Style({
                            stroke: new Stroke({
                                color: "#faf500",
                                width: 3,
                            }),
                        }),
                        warning: new Style({
                            stroke: new Stroke({
                                color: "#ff2800",
                                width: 3,
                            }),
                        }),
                        alert: new Style({
                            stroke: new Stroke({
                                color: "#c800ff",
                                width: 3,
                            }),
                        }),
                    };
                    const coastlineStyleFunction = (feature) => {
                        return coastlineStyles[areaLevelMap[feature.getProperties()["name"]]];
                    };
                    const tsunamiWarningLayer = new VectorLayer({
                        source: new VectorSource({
                            features: new GeoJSON().readFeatures(tsunamiAreaData, {
                                featureProjection: "EPSG:3857",
                            }),
                        }),
                        style: coastlineStyleFunction,
                    });
                    tsunamiWarningLayer.set("name", "tsunami_warning");
                    window.tsunamiWarningLayer = tsunamiWarningLayer;
                    map.addLayer(tsunamiWarningLayer);
                    map.getView().fit(tsunamiWarningLayer.getSource().getExtent(), {
                        padding: [50, 50, 50, 50],
                        maxZoom: 7,
                    });
                })()
            }
        } else if (report.name.includes("緊急地震速報")) {
            (async () => {
                setTitle(`${i18next.t("xml.report.name.eew")}\n${report.head.headline_text}`);
                if (report.head.info_type === "取消") {
                    if (report.text?.text) {
                        window.setFullscreenTip(report.text.text)
                        window.showFullscreenTip()
                    }
                    return;
                }
                const reportInfo = document.getElementById("reportInfo")
                const shindoInfo = () => {
                    const shindo = {}
                    if (report?.earth_quake?.intensity?.forecasts) {
                        report.earth_quake.intensity.forecasts.forEach((f) => {
                            let int = ""
                            if (f.int_form == f.int_to) {
                                int = f.int_to
                            } else if (f.int_to !== "over") {
                                int = f.int_to
                            } else {
                                int = f.int_from
                            }
                            if (shindo[int] == undefined) {
                                shindo[int] = []
                            }
                            if (!shindo[int].includes(f.area_name)) {
                                shindo[int].push(f.area_name)
                            }
                        })
                    }
                    return shindo
                }
                this.reportInfoWrite(shindoInfo(), reportInfo);
                const epicenter = [report.earth_quake.epicenters[0].longitude, report.earth_quake.epicenters[0].latitude]
                const shindoGeoJson = {
                    type: "FeatureCollection",
                    features: [],
                };
                const shindo = shindoInfo()
                if (shindo) {
                    for (const intensity of ["1", "2", "3", "4", "5-", "5+", "6-", "6+", "7"]) {
                        if (shindo[intensity]) {
                            for (const area of shindo[intensity]) {
                                if (areaData[area]) {
                                    shindoGeoJson.features.push({
                                        type: "Feature",
                                        geometry: {
                                            type: "Point",
                                            coordinates: areaData[area].coordinates,
                                        },
                                        properties: {
                                            intensity: intensity,
                                        },
                                    });
                                }
                            }
                        }
                    }
                }

                // 震中

                shindoGeoJson.features.push({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: epicenter,
                    },
                    properties: {
                        intensity: "x",
                    },
                });
                map.addLayer(JapanEEWMapLayer);
                const shindoStyle = {
                    x: new Style({
                        image: new Icon({
                            scale: 0.1,
                            src: "/data/images/epicenter.png",
                        }),
                        text: new TextStyle({
                            font: '13px sans-serif',
                            offsetY: 25,
                            text: report.earth_quake.epicenters[0].name,
                            placement: 'line',
                            fill: new Fill({
                                color: '#000'
                            }),
                            stroke: new Stroke({
                                color: '#fff',
                                width: 1
                            })
                        })
                    }),
                    1: new Style({
                        image: new Icon({
                            scale: 0.05,
                            src: "/data/images/is_1.png",
                        }),
                    }),
                    2: new Style({
                        image: new Icon({
                            scale: 0.05,
                            src: "/data/images/is_2.png",
                        }),
                    }),
                    3: new Style({
                        image: new Icon({
                            scale: 0.05,
                            src: "/data/images/is_3.png",
                        }),
                    }),
                    4: new Style({
                        image: new Icon({
                            scale: 0.05,
                            src: "/data/images/is_4.png",
                        }),
                    }),
                    "5-": new Style({
                        image: new Icon({
                            scale: 0.05,
                            src: "/data/images/is_5-.png",
                        }),
                    }),
                    "5+": new Style({
                        image: new Icon({
                            scale: 0.05,
                            src: "/data/images/is_5+.png",
                        }),
                    }),
                    "6-": new Style({
                        image: new Icon({
                            scale: 0.05,
                            src: "/data/images/is_6-.png",
                        }),
                    }),
                    "6+": new Style({
                        image: new Icon({
                            scale: 0.05,
                            src: "/data/images/is_6+.png",
                        }),
                    }),
                    7: new Style({
                        image: new Icon({
                            scale: 0.05,
                            src: "/data/images/is_7.png",
                        }),
                    }),
                };
                const styleFunction = (feature) => {
                    return shindoStyle[feature.getProperties()["intensity"]];
                };
                const shindoLayer = new VectorLayer({
                    source: new VectorSource({
                        features: new GeoJSON().readFeatures(shindoGeoJson, {
                            featureProjection: "EPSG:3857",
                        }),
                    }),
                    style: styleFunction,
                });
                shindoLayer.set("name", "shindo");
                window.shindoLayer = shindoLayer;
                var f = new GeoJSON;
                let EEWAreaData = f.writeFeatures(window.JapanEEWMapLayer.getSource().getFeatures(), { dataProjection: 'EPSG:4326', featureProjection: 'EPSG:3857' })
                EEWAreaData = JSON.parse(EEWAreaData)
                let EEWWarningAreas = []
                if (report?.earth_quake?.intensity?.forecasts) {
                    EEWWarningAreas = report.earth_quake.intensity.forecasts.filter((data) => {
                        if (data.category_name === "緊急地震速報（警報）") {
                            return data
                        }
                    })
                }
                if (EEWWarningAreas.length > 0) {
                    window.showEEWLegend()
                    let warningLegendText = i18next.t("map.warning_areas")
                    const warningAreasArray = []
                    EEWWarningAreas.forEach(area => {
                        if (!warningAreasArray.includes(area.pref_name)) {
                            warningAreasArray.push(area.pref_name)
                        }
                    })
                    warningAreasArray.forEach(text => {
                        warningLegendText += `\n${text}`
                    })
                    window.setEEWLegend(i18next.t("map.warning_areas"))
                    window.showInfoLegend()
                    window.setInfoLegend(warningLegendText)
                }
                const areaLevelMap = {};
                for (const eew of EEWWarningAreas) {
                    const prefCode = eew.pref_code.toString()
                    areaLevelMap[prefCode] = prefCode;
                }
                const areaNames = Object.keys(areaLevelMap);
                EEWAreaData.features = EEWAreaData.features.filter((f) => {
                    return areaNames.includes(f.properties.code);
                });
                const coastlineStyles = new Style({
                    fill: new Fill({
                        color: "#faf500",
                    }),
                    stroke: new Stroke({
                        color: '#aaa',
                        width: 1,
                    })
                })
                const coastlineStyleFunction = (feature) => {
                    return coastlineStyles;
                };
                const EEWLayer = new VectorLayer({
                    source: new VectorSource({
                        features: new GeoJSON().readFeatures(EEWAreaData, {
                            featureProjection: "EPSG:3857",
                        }),
                    }),
                    style: coastlineStyleFunction,
                });
                window.EEWLayer = EEWLayer;
                EEWLayer.set("name", "eew");

                map.addLayer(EEWLayer)
                map.addLayer(shindoLayer);
                const viewLayer = (EEWWarningAreas.length === 0 ? shindoLayer : EEWLayer)
                map.getView().fit(viewLayer.getSource().getExtent(), {
                    padding: [50, 50, 50, 50],
                    maxZoom: 7,
                });
            })();
        } else {
            if (report.head.info_type === "取消") {
                if (report.text?.text) {
                    window.setFullscreenTip(report.text.text)
                    window.showFullscreenTip()
                }
                return;
            }
            window.setTitle(report.name);
            reportInfo.innerHTML = `【${report.name}】<br/>`
            setFullscreenTip(i18next.t("map.tips.see_info"));
            showFullscreenTip();
        }
        if (report.text) {
            reportInfo.innerHTML += `<br/>${report.text.text}`
        }
    }
}
